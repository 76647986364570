//Base URL

//export const BASE_URL = "http://172.16.1.201:9088/api/";
//export const BASE_URL = "http://localhost:8081/api/";
// export const BASE_URL = "https://iceapi.trio-s.com/api/";
  export const BASE_URL = "https://api.iiceacademy.in/api/";

//export const BASE_URL= "https://uatapi.iiceacademy.in/api/";
//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";


//EXAMBOARDS
export const GET_EXAMBOARDS = "master/getExamBoardList";
export const ADD_NEW_EXAM_BOARD = "master/add/examBoard";
